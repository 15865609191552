<template>
    <v-container fluid>
        <v-card >
            <v-card-title>
                <v-row align="center">
                    <v-col cols="12" lg="4">
                        Arenas
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            v-model="search"
                            :loading="loading"
                            :search-input.sync="search"
                            menu-props="auto"
                            label="Search arena by name"
                            prepend-inner-icon="mdi-magnify"
                            hide-details
                            clearable
                            return-object
                        />
                    </v-col>
                    <v-col cols="12" lg="4" align="end">
                        <v-btn class="my-2 mr-2" dark small color="black" @click="createArena">
                            <v-icon dark>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
        </v-card>
        <array-view
            :items="arenas"
            :headers="displayInfo"
            :total-pages="totalPages"
            :page="page"
            :size="size"
            @handleClick="handleClick"
            @paginationChange="paginationChange"
            @onDelete="onDelete"
        />
    </v-container>
</template>

<script>
    import arenaService from '@/services/arena'
    import ArrayView from '@/components/ArrayView'

    export default {
        name: 'Arena',
        components: { ArrayView },
        data: () => {
            return {
                successfullImport: null,
                arenas: [],
                displayInfo: [
                    {
                        text: 'Id',
                        value: 'id'
                    },
                    {
                        text: 'Name',
                        value: 'name'
                    },
                    {
                        text: 'Coordinates',
                        value: 'coordinates'
                    },
                    {
                        text: 'Address',
                        value: 'address'
                    }
                ],
                showImportDialog: false,
                size: 5,
                totalPages: 0,
                page: 1,
                search: null,
                loading: false
            }
        },
        watch: {
            search() {
                if (this.search) {
                    this.fetchFilteredArenas()
                } else {
                    this.fetchAllArenas()
                }
            }
        },
        created() {
            this.page = this.$store.getters.getPage
            this.fetchAllArenas()
        },
        methods: {
            paginationChange(page, size) {
                this.size = size
                this.page = page
                this.$store.dispatch('setPage', page)
                this.fetchAllArenas()
            },
            handleClick(action, item) {
                this.$router.push(`/arenas/${item.id}/${action}`)
            },
            createArena() {
                this.$router.push(`/arenas/create`)
            },
            fetchAllArenas() {
                arenaService.getArenas(this.page, this.size).then(resp => {
                    this.arenas = resp.data.content
                    this.totalPages = resp.data.totalPages
                })
            },
            fetchFilteredArenas() {
                this.loading = true
                arenaService.getFilteredArenas(this.search, this.page, this.size).then(resp => {
                    this.arenas = resp.data.content
                    this.totalPages = resp.data.totalPages
                    this.loading = false
                })
            },
            onDelete(item, page) {
                arenaService.deleteArena(item.id).then(() => {
                    this.page = page
                    this.fetchAllArenas()
                })
            }
        }
    }
</script>

<style scoped>
.card-title {
    display: flex;
}

.v-list-item {
    min-height: 30px !important;
}

.v-card {
    min-width: 250px;
    position: relative;
}

.header-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
}

.header-buttons {
    align-self: center;
}

.edit-button:hover {
    background-color: whitesmoke;
    color: white;
}

.screen-card {
    padding: 4%;
}

.overflow-text {
    max-width: 79%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pagination {
    justify-content: center;
}
</style>
